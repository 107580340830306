import { modifiers } from '../data/shared';
import { type IFooterContent } from '../locales/@interfaces/shared/footerContent';
import { localizedContentService } from '../services';
import { UnderConstructionTemplate } from '../templates';

interface IUnderConstructionProps {
  footerContent: IFooterContent;
}

const UnderConstruction = (props: IUnderConstructionProps) => (
  <UnderConstructionTemplate {...props} />
);

export const getStaticProps = async () => {
  const { footerContent } = localizedContentService<{
    footerContent: IFooterContent;
  }>(
    'pt-BR',
    ['footerContent'],
    [modifiers.STATIC_ASSETS_PATH, modifiers.CURRENT_YEAR]
  );

  return {
    props: {
      footerContent,
    },
  };
};

export default UnderConstruction;
